:root{
  --ag-border-radius: 18px!important;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.ag-theme-alpine {
  border-radius:18px !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {font-family: 'Diodrum';
  src: url('https://s3.ap-south-1.amazonaws.com/mail.kritsnam.com/fonts/Diodrum-Medium.woff') format('woff'),;}
  font-face {   font-family: 'Diodrum';  src: url('https://s3.ap-south-1.amazonaws.com/mail.kritsnam.com/fonts/Diodrum-Semibold.woff') format('woff'),;font-weight: bold;}
   * {font-family: Diodrum;}