.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.gaugepos{
  position: absolute;
  margin-top:1rem;
  margin-left:-1rem;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.custom-border {
  border-top: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-left: 4px solid #28a745; /* Adjust the green color as needed */
}
.custom-borderred {
  border-top: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-left: 4px solid red; /* Adjust the green color as needed */
}
.datedata{
  float: right;
  padding: 5px 16px 5px 16px;
  margin-top: -35px;
  margin-right: 4px;
  border-radius: 17px;
  height: 70px;
  width: 70px;
}
.datedataala{
  float: right;
  padding: 16px 16px 16px 16px;
  margin-top: -35px;
  margin-right: 4px;
  border-radius: 17px;
  height: 70px;
  width: 70px;
}
.lbltext{
  float: left;
  padding: 0px 0px 5px 0px;
  margin-top: -22px;
}
.greylight{
  color: #6E7583;

}
.bordergrey{
  border: 1px solid #ccc;
}
.greycolor{
  color:  #1B1B1C;
  
}
.headingcolor{
  color: #202224;

}

.lighttextmeter{
  color: #151920;
  opacity: 0.6;
}
.bg-yellow{
  background: #FFCC75;

}

.bg-greycolor{
background-color:#F5F6FA;}
.bg-bluecolor{
  background-color:#2B3C73;
}
.hover\:bg-bluetextcolor:hover{
  background-color:#2B3C73;
  color:#ffffff;
}
.rounded-4xl{
  border-radius:2rem;
}

.activetag{
  right: 0;
    border-radius: 0px 16px 0px 9px;
    top: 0;
    background-color: #25832B;
    color: white;
}
.inactivetag{
  right: 0;
    border-radius: 0px 16px 0px 9px;
    top: 0;
    background-color: #C83C36;
    color: white;
}
.bg-g{
  background-color: #25832B;
}
.bg-r{
  background-color: #C83C36;
}
.backgroundyellow{
  background-color: #FFCC75;
}
.dashbpadding{
  padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem;
    padding-left: 1rem;
}
.dashbpaddings{
  padding-top: 1rem;
  
    padding-right: 1rem;
    padding-left: 1rem;
}
.meterpadding{
  padding-top: 1.5rem;
  padding-bottom: 1rem;
    padding-right: 1rem;
    padding-left: 1rem;
}
.supportpadding{
  padding-top: 1.5rem;
  padding-bottom: 1rem;
    padding-right: 1rem;
    padding-left: 0.5rem;
}
.ag-theme-alpine .ag-root-wrapper {
  border: none !important; /* Remove the border */
}
.statusgreen{
  color: white;
    padding: 3px 10px 3px 10px;
    border: 1px solid #ccc;
    border-radius: 1px 1px 1px 8px;
    background: #25832B;
}
.statusred{
  color: white;
    padding: 3px 10px 3px 10px;
    border: 1px solid #ccc;
    border-radius: 1px 1px 1px 8px;
    background: #C83C36;
}
.settingsbuttom{
  background: #FFCC75;
  color:#2B3C73;
  border:1px solid #2B3C73;
  font-weight: 600;


}
.textprimary{
  color:#2B3C73;
  font-weight:600;
 background-image: url('assets/bb.svg');
 background-position: bottom;
 background-repeat: no-repeat;
 background-size: contain;
}
.primarycolor{
  color:#2B3C73;
  background:#fff;
  border: 1px solid #2B3C73;
}
.map-container {
  position: relative;
  height: 100%;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 10px;
}
.mapv {
  font-size: x-large;
  color: #2B3C73;
  font-weight: 600;
}

.grayscale-map {
  filter: grayscale(100%);
}

.custom-popup .leaflet-popup-content-wrapper {
  background-color: white;
  border-radius: 8px;
  padding: 15px;
  width: 200px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.popup-content h4 {
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 10px;
}

.popup-content p {
  font-size: 14px;
  margin: 0;
  color: #555;
}

.popup-content .popup-data {
  font-size: 24px;
  font-weight: bold;
  color: #2C3E50;
}
.textblue{
  color:#2B3C73;
  font-weight:600;
}
.bgprima{
  color:#2B3C73;
  font-weight:600;
 
}
.bb{
  border-bottom: 0.4px solid #979797;
  padding-bottom: 1rem;
}
.hover\:bgprima:hover {

  background-color:#2B3C73;
  color:#fff;
  font-weight: 600;
  border-radius:5px;
  border:1px solid #2B3C73;
 
}

.border-left-custom::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 5px;
  background: transparent; /* Default color when not hovered */
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  transition: background 0.3s ease; /* Smooth transition for hover effect */
}
.border-left-custom:hover::before {
  background: #2B3C73; /* Color when hovered */
  
}
.relative-container {
  position: relative;
  border-radius: 5px;
  background-color: white; /* background color for demonstration */
}
.svg-hover {
  transition: filter 0.3s ease;
}

.svg-hover:hover {
  filter: invert(1) hue-rotate(180deg);
}
.hover\:bgprima:hover .svg-hover {
  filter: invert(1) hue-rotate(180deg); /* Adjust these values as needed */
}

.datedatam {
  float: right;
  padding: 2px 4px 2px 4px;
  margin-top: -4px;
  margin-right: -3px;
  border-radius: 12px;
  height: 46px;
  width: 47px;
}
.lbltextm {
  float: left;
  padding: 0px 0px 5px 0px;
  margin-top: -43px;
}

@media (max-width: 640px) {
  .buybutton {
    font-size: 9px;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  }
  .meterbut{
    font-size: 8px;
    padding: 6px 2rem 6px 1rem;
  
  }
  .meterpadding{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
      padding-right: 0.25rem;
      padding-left: 0.25rem;
  }
}

