.customOverlay {
    background: rgba(36, 123, 160, 0.7);
  }
  .rc_rootd{
  
    overflow: overlay;
    z-index: 99999;
    position: absolute;
    margin-top: 3%;
    margin-left: 1%;
  }
  .calendar-container {
    overflow: overlay;
    z-index: 99999;
    position: absolute;
    margin-top: 3%;
    margin-left: 1%;
    background: white; /* Ensure the background is white or any color you prefer */
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .calendar-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  
  .apply-button, .cancel-button {
    background-color: #2B3C73; /* Blue color for Apply button */
    color: white;
    border: none;
    padding: 4px 7px;
    font-size:smaller;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .cancel-button {
    background-color: #B0B5BF; /* Red color for Cancel button */
  }
  
  .apply-button:hover, .cancel-button:hover {
    opacity: 0.9;
  }
  
